<template>
    <div class="sub_title">
        <h2 :style="{ color: titleColor }">
            <slot name="title"></slot>
        </h2>
        <div class="bg" :style="{backgroundImage: `url(${bgurl})`,}"></div>
    </div>
</template>

<script>
export default {
    props: {
        titleColor: {
            type: String,
            default: '#005FC6'
        },
        bgurl: {
            type: String,
            default: require('@/assets/images/zuixindongtai.png')
        }
    }
}
</script>

<style lang="scss" scoped>
.sub_title {
    text-align: center;
    h2 {
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #005FC6;
    }
    .bg{
        height: 51px;
        background-repeat: no-repeat;
        background-position: center center;
        margin-top: -15px;
    }
}
</style>