<template>
    <div class="news_item">
        <div class="time_box">
            <p class="month">{{parseDate(newsItem.sortsTime,`{mm}`)}}·{{ parseDate(newsItem.sortsTime,`{dd}`) }}</p>
            <p class="year">{{parseDate(newsItem.sortsTime,`{yyyy}`)}}</p>
        </div>
        <div  class="img_box">
            <el-image
            style="width: 220px; height: 160px"
            :src="baseUrl+newsItem.pic"
            fit="cover"></el-image>
        </div>
        <div class="content">
            <div class="title">{{newsItem.title}}</div>
            <div class="text" v-html="newsItem.content"></div>
        </div>
    </div>
</template>

<script>
import {parseDate} from '@/utils'
    export default {
        props:{
            newsItem:{
                type:Object,
                default:{}
            }
        },
        data(){
            return {
                baseUrl: 'http://www.jwxx.com.cn/prod-api'
                // baseUrl:process.env.VUE_APP_BASE_API
            }
        },
        methods:{
            parseDate,
        }
    }
</script>

<style lang="scss" scoped>
.news_item{
    display: flex;
    padding: 37px 45px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.05);
    border-radius: 4px;
    .time_box{
        width: 100px;
        .month{
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 1;
            margin-bottom: 10px;
        }
        .year{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #99999980;
            padding-bottom: 36px;
            width: 60px;
            position: relative;

            &::before{
                content: ' ';
                position: absolute;
                left: 0;
                bottom: 0;
                width: calc(100% - 8px);
                height: 4px;
                background-color: #DD5839;
                transition: all .1s;
            }
            &::after{
                content: ' ';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 4px;
                height: 4px;
                background-color: #DD5839;
                transition: all .1s;
            }
        }
    }
    .img_box{
        width: 220px;
    }
    .content{
        flex: 1;
        padding-left: 40px;
        .title{
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #222222;
            margin-bottom: 16px;
            transition: all .1s;
        }
        .text{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 2;
            height: 111px;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }
    &:hover{
        .content{
            .title{
                color: #005FC6;
            }
        }
        .time_box{
            .year{
                &::before{
                    width: 4px;
                }
                &::after{
                    width: calc(100% - 8px);
                }
            }
        }
    }
}
</style>
