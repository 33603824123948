<template>
    <div>
        <div class="banner">
            <img src="~@/assets/images/dongtai_banner.png" alt="">
        </div>
        <div class="news_countent">
            <NewsTitle class="news_title">
                <template v-slot:title>最新动态</template>
            </NewsTitle>
            <div class="news_list" v-if="newsList.length>0" v-loading="loading">
                <div class="inner">
                    <news-item class="news_item" v-for="item in newsList" :newsItem="item" :key="item.id" @click.native="handleNewsDetail(item)"/>
                </div>
            </div>
            <div class="page_box">
                <el-pagination
                v-if="total>0"
                background
                :page-size="queryParams.pageSize"
                :hide-on-single-page="false"
                layout="prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange">
                </el-pagination>
            </div>
            <el-empty v-if="newsList.length==0" description="暂无招聘信息"></el-empty>
        </div>
    </div>
</template>

<script>
import { getNewsList } from '@/api/aboutus'
import NewsTitle from '../components/Title/NewsTitle.vue'
import NewsItem from '../components/NewsItem'

import { animate } from "../mixin/animate"
export default {
    name:'CompanyNews',
    mixins: [ animate ],
    components:{
        NewsTitle,
        NewsItem
    },
    data(){
        return {
            queryParams:{
                pageSize:10,
                pageNum:1
            },
            total:0,
            newsList:[],
            loading:false,

            animateList: [
                {
                  node: 'news_ani',
                  class: ()=>{
                    return 'animate__flipInY'
                  }
                }
            ]
        }
    },
    created(){
        this.getNewsList()
    },
    methods:{

        handleCurrentChange(val){
            this.queryParams.pageNum = val
            this.getNewsList()
        },
        handleNewsDetail(data){
            this.$router.push({name:"NewsItem",params:{id:data.id}})
        },
        getNewsList(){
            this.loading = true
            getNewsList(this.queryParams).then(res=>{
                console.log(res)
                this.newsList = res.rows
                this.total = res.total
            }).finally(()=>{
                this.loading = false
            })
        },



    }
}
</script>

<style lang="scss" scoped>
.news_countent{
    background-color:#FBFBFB;
    padding-top: 70px;
    padding-bottom: 50px;
    .news_title{
        margin-bottom: 56px;
    }
    .news_item{
        margin-bottom: 40px;
        cursor: pointer;
    }
    .page_box{
        text-align: center;
    }
}
</style>
